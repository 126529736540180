import './layout/_header'
import './layout/_footer'
import '../scss/single.scss'
import { sectionRelated } from 'JS/components/_section-related';
import { formInitSelects, formInitContact } from 'JS/components/_contact';

(function () {
    
    window.addEventListener('DOMContentLoaded', () => {
        sectionRelated();
        formInitSelects('.js-event-contact-wrapper');
        formInitContact('.js-event-contact-wrapper', 'event');
        formInitSelects('.js-form-contact-wrapper');
        formInitContact('.js-form-contact-wrapper', 'contact');
    })

})();